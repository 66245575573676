import { useState } from "react";

const Servers = () => {
   const [userId, setUserId] = useState('');

   return (
      <div className="grid items-center gap-y-4 w-full">
         <h1 className='text-4xl text-center'>Тут будет полезная информация</h1>

         <input type="text" value={userId} onChange={e => setUserId(e.target.value)} />
         <a href={`/user/${userId}`}>Открыть профиль</a>
      </div>
   );
};

export default Servers;