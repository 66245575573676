import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {BrowserRouter} from "react-router-dom";
import ReactDOM from 'react-dom/client';
import { Toaster } from 'sonner';
import App from './App';
import './index.css';


const client = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   <QueryClientProvider client={client}>
      <BrowserRouter>
         <App />
         <Toaster />
      </BrowserRouter>
   </QueryClientProvider>
);

