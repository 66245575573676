import React from 'react';
import Header from "../modules/header";
import {Outlet} from "react-router-dom"; 

const Layout = () => { 
   return (
      <>
         <Header/>
         <main className='max-w-sm md:max-w-2xl xl:max-w-4xl mx-auto mt-10 h-[calc(100vh_-_7.5rem)]'>
            <Outlet/>
         </main>
      </>
   );
};

export default Layout;