import {useQuery} from "@tanstack/react-query";
import IsLoading from "../components/IsLoading";
import {getPayokOne} from "../api/payokAPI";
import {Link, useParams} from "react-router-dom";
import {getUsername} from "../api/userAPI";

const PayokOne = () => {
   const {id: chatId} = useParams()
   const {data, isLoading} = useQuery({queryKey: [`payok`], queryFn: () => getPayokOne(chatId)})
   const {data: user, isLoading: userIsLoading} = useQuery({queryKey: [`payok ${chatId}`], queryFn: () => getUsername(chatId)})
   return (
      <div>
         {userIsLoading?
            <h1 className='text-4xl text-center'>Подгрузка пользователя..</h1>
            :
            <h1 className='text-4xl text-center'><Link to={`/user/${chatId}`}>@{user.username}</Link> операции</h1>
         }

         <IsLoading isLoading={isLoading}>
            <div className="grid items-center gap-y-4 w-full mt-8">
               {
                  data?.map(transaction =>
                     <div key={transaction.id} className={`bg-gray-100 p-4 rounded-md flex justify-between ${transaction.status && 'bg-green-200'}`}>
                        <p>{transaction.id}</p>
                        <p>{transaction.product}</p>
                        <p>{transaction.createdAt.slice(0, 11).replace('T', ' ')}
                           <span className='text-xs text-green-700'>{transaction.createdAt.slice(11, 16).replace('T', ' ')}</span>
                        </p>
                        
                        <p>Key {transaction.keyId}</p>
                        <p>{transaction.sum} ₽</p>
                     </div>
                  )
               }
            </div>
         </IsLoading>
      </div>
   );
}

export default PayokOne;