import { Route, Routes } from "react-router-dom";
import MessagePage from "./pages/MessagePage";
import Dashboard from "./pages/Dashboard";
import Layout from "./components/Layout";
import PayokOne from "./pages/PayokOne";
import UserRefs from "./pages/UserRefs";
import UserLogs from "./pages/UserLogs";
import Payok from "./pages/Payok";
import Check from "./pages/Check";
import User from "./pages/User";

function App() {
   return (
      <Routes>
         <Route path='/' element={<Layout />}>

            <Route index element={<Dashboard />} />

            <Route path='user/:id' element={<User />} />
            <Route path='user/:id/refs' element={<UserRefs />} />
            <Route path='user/:id/logs' element={<UserLogs />} />

            <Route path='check' element={<Check />} />
            <Route path='message' element={<MessagePage />} />

            <Route path='payok' element={<Payok />} />
            <Route path='payok/:id' element={<PayokOne />} />

            <Route path='*' element={<h1 className='h-full flex justify-center items-center pb-[20%] text-4xl font-bold'>404 page</h1>} />
         </Route>
      </Routes>
   );
}

export default App;
