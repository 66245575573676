import axios from "axios";

export async function getKeys(){
   const {data} = await axios.get(process.env.REACT_APP_API_URL + '/key')
   
   return data.sort((a, b) => a.id - b.id)
}
export async function getKeysToCheck(){
   const {data} = await axios.get(process.env.REACT_APP_API_URL + '/key/check')
   return data.data
}
export async function setKeyInstagramChecked(keyId, isOk){
   const {data} = await axios.post(process.env.REACT_APP_API_URL + `/key/check/${keyId}`, {isOk})
   return data.data
}