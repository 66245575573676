import { useState } from "react";
import { sendMessages } from "../api/botApi";

const MessagePage = () => {
   const [chatIds, setChatIds] = useState('')
   const [message, setMessage] = useState('')

   return (
      <div className="flex flex-col gap-4">
         <textarea name="chatIds" value={chatIds} onChange={(e) => {
            setChatIds(e.target.value)
         }} placeholder='chatIds' className="rounded-md p-2 border-[1px] border-stone-300 w-full"></textarea>

         <textarea name="message" value={message} onChange={(e) => {
            setMessage(e.target.value)
         }} placeholder='сообщение' className="rounded-md p-2 border-[1px] border-stone-300 w-full"></textarea>

         <div className="flex-1 flex justify-end">
            <button className="p-2 rounded-md bg bg-slate-200 hover:bg-slate-300"
               onClick={(e) => {
                  e.currentTarget.disabled = true;
                  try {
                     sendMessages(chatIds.split('\n'), message)
                  }
                  catch(e) {
                     alert(e.message);
                  }

                  e.currentTarget.disabled = false;

               }}>Отправить</button>
         </div>
      </div>
   );
};

export default MessagePage;