import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const copyToClipboard = async (event, text) => {
  await navigator.clipboard.writeText(text);

  const button = event.target.closest('.btn_to_copy')
  button.children[0].innerText = 'скопировано'
  setTimeout(() => {
     button.children[0].innerText = ''
  }, 400)
}
