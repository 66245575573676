import {useState} from 'react';
import {useQuery} from "@tanstack/react-query";
import IsLoading from "../components/IsLoading";
import {getPayokAll} from "../api/payokAPI";
import {Link} from "react-router-dom";

const Payok = () => {
   const {data, isLoading} = useQuery({queryKey: [`payok`], queryFn: getPayokAll})

   const [filter, setFilter] = useState([])

   function activateFilter(e){
      const filterValue = e.target.dataset.filter;
      const index = filter.indexOf(filterValue)

      if (index > -1){
         setFilter(filter.toSpliced(index, 1))
      }
      else{
         setFilter([...filter, filterValue])
      }
   }

   return (
      <div>
         <h1 className='text-4xl text-center'>Payok платежи</h1>
         <div className='flex justify-center gap-4 mt-4'>
            <button className={`p-1 border-[1px] rounded-md ${filter.includes('extension') ? 'active' : ''}`} data-filter='extension' onClick={e => activateFilter(e)}>продление</button>
            <button className={`p-1 border-[1px] rounded-md ${filter.includes('payok') ? 'active' : ''}`} data-filter='payok' onClick={e => activateFilter(e)}>payok</button>
            <button className={`p-1 border-[1px] rounded-md ${filter.includes('ref') ? 'active' : ''}`} data-filter='ref' onClick={e => activateFilter(e)}>ref</button>
            <button className={`p-1 border-[1px] rounded-md ${filter.includes('keys') ? 'active' : ''}`} data-filter='keys' onClick={e => activateFilter(e)}>ключи</button>
            <button className={`p-1 border-[1px] rounded-md ${filter.includes('returned') ? 'active' : ''}`} data-filter='returned' onClick={e => activateFilter(e)}>возврат</button>
         </div>
         <IsLoading isLoading={isLoading}>
            <div className="grid items-center gap-y-4 w-full mt-8">
               { 
                  data?.map(transaction => {

                        let condition = false;
                        const product = transaction.product.toLowerCase()

                        if (filter.includes('extension') && product.includes('продление'))
                              condition = true;

                        if (filter.includes('payok') && product.includes('оплата'))
                              condition = true;
                        
                        if (filter.includes('ref') && product.includes('рефер'))
                              condition = true;
                        
                        if (filter.includes('keys') && product.includes('покупка'))
                              condition = true;

                        if (filter.includes('returned') && product.includes('возврат'))
                              condition = true;
                        
                        if (filter.length === 0) condition = true;

                        if (!condition) return '';

                        return <Link to={`/payok/${transaction.chatId}`} key={transaction.id}
                                     className={`bg-gray-100 p-4 rounded-md flex justify-between ${transaction.status && 'bg-green-200'}`}>
                           <p>{transaction.id}</p>
                           <p>{transaction.product}</p>
                           <p>{
                              transaction.createdAt.slice(8, 10) + '/' +
                              transaction.createdAt.slice(5, 7) + '/' +
                              transaction.createdAt.slice(0, 4)
                           }
                              <span
                                 className='text-xs text-green-700'> {transaction.createdAt.slice(11, 16).replace('T', ' ')}</span>
                           </p>
                           <p>Key {transaction.keyId}</p>
                           <p>{transaction.chatId}</p>
                           <p>{transaction.sum} ₽</p>
                        </Link>
                     }
                  )
               }
            </div>
         </IsLoading>
      </div>
   );
}

export default Payok;