import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import {
   Bars3Icon,
   XMarkIcon,
} from '@heroicons/react/24/outline'
import { Link } from "react-router-dom";


const menu = [
   { name: 'Проверка', href: 'check' },
   { name: 'Отправить сообщение', href: 'message' },
]
export default function Header() {
   const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

   return (
      <header className="bg-white">
         <nav className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8" aria-label="Global">
            <div className="flex lg:flex-1">
               <Link to="/" className="-m-1.5 p-1.5">
                  <span className="sr-only">VPN service</span>
                  <img className="h-8 w-auto" src="https://cdn-icons-png.flaticon.com/512/4264/4264045.png" alt="" />
               </Link>
            </div>
            <div className="flex lg:hidden">
               <button
                  type="button"
                  className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
                  onClick={() => setMobileMenuOpen(true)}
               >
                  <span className="sr-only">Открыть меню</span>
                  <Bars3Icon className="h-6 w-6" aria-hidden="true" />
               </button>
            </div>
            <div className='flex gap-4'>
               {menu.map((item) => (
                  <Link key={item.name} to={item.href} className="text-sm font-semibold leading-6 text-gray-900">
                     {item.name}
                  </Link>
               ))}
            </div>
         </nav>
         <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
            <div className="fixed inset-0 z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
               <div className="flex items-center justify-between">
                  <Link to="/" className="-m-1.5 p-1.5">
                     <span className="sr-only">VPN service</span>
                     <img
                        className="h-8 w-auto"
                        src="https://cdn-icons-png.flaticon.com/512/4264/4264045.png"
                        alt=""
                     />
                  </Link>
                  <button
                     type="button"
                     className="-m-2.5 rounded-md p-2.5 text-gray-700"
                     onClick={() => setMobileMenuOpen(false)}
                  >
                     <span className="sr-only">Close menu</span>
                     <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
               </div>
               <div className="mt-6 flow-root">
                  <div className="-my-6 divide-y divide-gray-500/10">
                     <div className="space-y-2 py-6">
                        {menu.map((item) => (

                           <Link
                              key={item.name}
                              to={item.href}
                              className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                           >
                              {item.name}
                           </Link>
                        ))}
                     </div>
                  </div>
               </div>
            </Dialog.Panel>
         </Dialog>
      </header>
   )
}
