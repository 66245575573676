import {Link, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getUserRefs} from "../api/userAPI"; 
import IsLoading from '../components/IsLoading';

const UserRefs = () => {
   const {id: chatId} = useParams()
   const {data: user, isLoading} = useQuery({queryKey: [`user ${chatId}`, 'logs'], queryFn: () => getUserRefs(chatId)})
   return (
      <div>
         {isLoading?
            <h1 className='text-4xl text-center'>Подгрузка пользователя..</h1>
            :
            <h1 className='text-4xl text-center'><Link to={`/user/${chatId}`}>@{user?.name}</Link> рефералы</h1>
         }

         <IsLoading isLoading={isLoading}>
            <div className="grid items-center gap-y-4 w-full mt-8">
               {
                  user?.refs?.length ?
                  <div>Всего рефералов: {user?.refs.length}</div>
                  :
                  <div>Нет Рефералов</div>
               }
               {
                  user?.refs?.sort().map(ref =>
                     <a href={`/user/${ref.chatId}`} key={ref.chatId} className={`bg-gray-100 p-4 rounded-md flex justify-between ${ref.isUseNow && 'bg-green-200'}`}>
                        <p>{ref.chatId}</p>
                        <p>{ref.sum ?? 0}({ref.count ?? 0})</p>
                        <p>{ref.createdAt.slice(0, 19).replace('T', ' ')}</p>
                     </a>
                  )
               }
            </div>
         </IsLoading>
      </div>
   );
};

export default UserRefs;