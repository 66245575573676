import axios from "axios";


export async function getUsers() { 
   const {data} = await axios.get(process.env.REACT_APP_API_URL + '/user')
   return data.data
}
export async function getUsername(chatId) {
   const {data} = await axios.get(process.env.REACT_APP_API_URL + '/user/username/' + chatId)
   return data.data
}
export async function getUser(chatId) { 
   const {data} = await axios.get(process.env.REACT_APP_API_URL + '/user/' + chatId)
   return data.data
}
export async function getUserLogs(chatId) { 
   const {data} = await axios.get(process.env.REACT_APP_API_URL + '/user/' + chatId + '/logs')
   return data.data
}
export async function getUserRefs(chatId) { 
   const {data} = await axios.get(process.env.REACT_APP_API_URL + '/user/' + chatId + '/refs')
   return data
}
export async function topUpUserBalance(chatId, sum) {
   const {data} = await axios.put(process.env.REACT_APP_API_URL + '/user/' + chatId + '/balance', {sum})
   return data.data
}
export async function saveUserRefFreeDays(chatId, duration) {
   const {data} = await axios.post(process.env.REACT_APP_API_URL + '/user/setRefFreeDays', {chatId, duration})
   return data
}