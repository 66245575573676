import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import { copyToClipboard } from "../lib/utils";
import { getKeysToCheck, setKeyInstagramChecked } from "../api/keysAPI";
import IsLoading from "../components/IsLoading";

const Check = () => {
   const {data, isLoading, refetch} = useQuery({queryKey: [`check`], queryFn: getKeysToCheck})
   return (
      <div>
         <h1 className='text-4xl text-center'>Требуют проверки</h1>

         <IsLoading isLoading={isLoading}>
            <div className="grid items-center gap-y-4 w-full mt-8">
               {
                  data?.sort((a, b) => a.id - b.id)?.map(key =>
                     <Link to={`/user/${key.chatId}`} key={key?.id} className='bg-gray-100 p-4 rounded-md flex justify-between'>
                        <p>#{key?.id}</p>
                        <p className="flex gap-2">{key?.instagram}
                           <button
                              className='text-xs flex bg-gray-300 text-gray-700 rounded-md p-1 btn_to_copy hover:scale-110 hover:bg-gray-400'
                              onClick={(e) =>{
                                 e.preventDefault()
                                 copyToClipboard(e, key?.instagram)
                              }}
                           >
                              <svg className='w-[1rem] h-[1rem]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <path d="M6.59961 11.3974C6.59961 8.67119 6.59961 7.3081 7.44314 6.46118C8.28667 5.61426 9.64432 5.61426 12.3596 5.61426H15.2396C17.9549 5.61426 19.3125 5.61426 20.1561 6.46118C20.9996 7.3081 20.9996 8.6712 20.9996 11.3974V16.2167C20.9996 18.9429 20.9996 20.306 20.1561 21.1529C19.3125 21.9998 17.9549 21.9998 15.2396 21.9998H12.3596C9.64432 21.9998 8.28667 21.9998 7.44314 21.1529C6.59961 20.306 6.59961 18.9429 6.59961 16.2167V11.3974Z" fill="#1C274C"/>
                                 <path opacity="0.5" d="M4.17157 3.17157C3 4.34315 3 6.22876 3 10V12C3 15.7712 3 17.6569 4.17157 18.8284C4.78913 19.446 5.6051 19.738 6.79105 19.8761C6.59961 19.0353 6.59961 17.8796 6.59961 16.2167V11.3974C6.59961 8.6712 6.59961 7.3081 7.44314 6.46118C8.28667 5.61426 9.64432 5.61426 12.3596 5.61426H15.2396C16.8915 5.61426 18.0409 5.61426 18.8777 5.80494C18.7403 4.61146 18.4484 3.79154 17.8284 3.17157C16.6569 2 14.7712 2 11 2C7.22876 2 5.34315 2 4.17157 3.17157Z" fill="#1C274C"/>
                              </svg>
                           </button>
                        </p>
                           
                        <p>{key?.createdAt.slice(0, 11).replace('T', ' ')}
                           <span className='text-xs text-green-700'>{key?.createdAt.slice(11, 16).replace('T', ' ')}</span>
                        </p>
                        <div className='flex gap-2'>
                           <button
                              onClick={e => {
                                 e.preventDefault()
                                 setKeyInstagramChecked(key.id, false).then(() => refetch())
                              }}
                              className='bg-gray-300 hover:bg-gray-400 rounded-md px-2'
                           >❌</button>
                           <button
                              onClick={e => {
                                 e.preventDefault()
                                 setKeyInstagramChecked(key.id, true).then(() => refetch())
                              }}
                              className='bg-gray-300 hover:bg-gray-400 rounded-md px-2'
                           >ok</button>
                        </div>
                     </Link>
                  )
               }
            </div>
         </IsLoading>
      </div>
   );
};

export default Check;