import axios from "axios";

export async function deleteKey(keyId){
   const {data} = await axios.delete(process.env.REACT_APP_API_URL + `/server/${keyId}`)
   return data
}
export async function updateKey(serverId, keyId, isOutline = false){
   const {data} = await axios.put(process.env.REACT_APP_API_URL + `/server/${serverId}/key/${keyId}`, {
      isOutline
   })
   return data
}