import React, { useEffect, useState } from 'react';
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { deleteKey } from "../api/serverAPI";
import { getUser, saveUserRefFreeDays, topUpUserBalance } from "../api/userAPI";
import { daysWithHoursToDate } from "../helpers";
import Loader from "../components/Loader";
import { toast } from 'sonner';

export function bytesToGB(bytes) {
   const gigabytes = bytes / (1024 * 1024 * 1024);
   return gigabytes.toFixed(2);
}

const Server = () => {
   const { id: chatId } = useParams()
   const { data, isLoading, refetch } = useQuery({ queryKey: [`user ${chatId}`], queryFn: () => getUser(chatId) })

   const [duration, setDuration] = useState('')

   const sums = [
      100, 70, 50, 10
   ]

   useEffect(() => {
      setDuration(data?.refFreeDays ?? '');
   },
      [data])

   return (
      isLoading ?
         <Loader />
         :
         data ?
            <>
               <div className='flex justify-center gap-2'>
                  {
                     data?.photos[0] &&
                     <img src={data?.photos[0]} alt="photo" className='h-[90px] w-[90px] rounded-full shadow-indigo-500/50' />
                  }
                  <h1 className='text-4xl text-center flex flex-wrap items-center justify-center gap-4'>
                     @{data?.username ?? data?.fio}
                     {chatId > 100000 &&
                        <>
                           <span className='text-sm'>Баланс: {data?.balance}</span>
                           <Link className='text-xs p-1 rounded-xl ring-1 ring-gray-200 hover:bg-gray-100' to={`/payok/${chatId}`}>payok</Link>
                           <Link className='text-xs p-1 rounded-xl ring-1 ring-gray-200 hover:bg-gray-100' to={`/user/${chatId}/logs`}>logs</Link>
                           {
                              sums.map(sum => {
                                 return (<button
                                    className='text-xs p-1 rounded-xl ring-1 ring-gray-200 hover:bg-gray-100'
                                    onClick={e => {
                                       e.target.innerText = 'Отправка..';
                                       topUpUserBalance(chatId, sum)
                                          .then(() => {
                                             refetch()
                                             e.target.innerText = `+${sum}💲`
                                          })
                                          .catch(() => { e.target.innerText = `+${sum}💲` })
                                    }}
                                 >+{sum}💲</button>)
                              })
                           }
                           
                           {
                              sums.map(sum => {
                                 return (<button
                                    className='text-xs p-1 rounded-xl ring-1 ring-gray-200 hover:bg-gray-100'
                                    onClick={e => {
                                       e.target.innerText = 'Отправка..';
                                       topUpUserBalance(chatId, -sum)
                                          .then(() => {
                                             refetch()
                                             e.target.innerText = `-${sum}💲`
                                          })
                                          .catch(() => { e.target.innerText = `-${sum}💲` })
                                    }}
                                 >-{sum}💲</button>)
                              })
                           }
                        </>
                     }
                  </h1>
               </div>
               <div className='mt-4'>
                  {data?.owner && <p>Приглашен: <Link to={`/user/${data.owner}`}>{data.owner}</Link></p>}
                  <p><a href={`/user/${chatId}/refs`}>Пригласил</a>: {data?.refs?.count ?? 0}</p>
                  <p>Пригласил и оплатили: {data?.refs?.countActive ?? 0}</p>
                  <p>Заработал: {data?.refs?.earned ?? 0}</p>
                  <p>Доступно к выводу: {data?.refs?.balance ?? 0}</p>
                  <p>Вывел: {data?.refs?.withdrawn ?? 0}</p>
                  <p>
                     Доп дни, от реф. ключа: <input className='w-[50px]' type="number" value={duration} onChange={e => setDuration(e.target.value)} />
                     <button className="bg-green-300 rounded-md p-1" onClick={() => saveUserRefFreeDays(chatId, duration).then(data => {
                        if (data.message === 'ok') toast.success('Данные успешно обновлены')
                        else toast.error(data.message)
                     })}>save</button>
                  </p>
                  {chatId > 100000 &&
                     <>
                        <p>Подписок: {data?.keys?.length}</p>
                        <p>Стоимость подписок: {data?.keys?.reduce((acc, el) => acc + +el.cost, 0) || 0}</p>
                     </>
                  }
                  <p>Зарегестрирован с {data?.createdAt.slice(0, 10)}</p>
               </div>
               {
                  chatId > 100000 &&
                  <div className="grid items-center gap-y-4 w-full mt-8">
                     {
                        data?.keys?.length > 0 ?
                           data?.keys?.map(key =>
                              <div key={key.username} id={`key_${key.username}`} className='bg-gray-100 p-4 rounded-md flex justify-between'>
                                 <p>Key {key.username}</p>
                                 <p className={(key.name ? '' : 'underline rounded-md') + ' text-sm'}>{key.name || 'Демо'}</p>
                                 <p>{bytesToGB(key.lifetime_used_traffic)}mb</p>
                                 <p>{daysWithHoursToDate(key.expire)}</p>
                                 <button
                                    className='text-xs bg-red-500 text-white rounded-md p-1'
                                    onClick={(e) => {
                                       e.preventDefault()

                                       const key_html = document.getElementById(`key_${key.username}`);

                                       key_html.style.opacity = '.5'
                                       key_html.style.pointerEvents = 'none'
                                       deleteKey(key.username)
                                          .then(() => refetch())
                                          .catch(() => {
                                             key_html.style.opacity = null
                                             key_html.style.pointerEvents = null
                                          })
                                    }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#ffffff" width="16px" height="16px" viewBox="0 0 24 24"><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" /></svg>
                                 </button>
                              </div>
                           )
                           :
                           <div className='bg-gray-100 p-4 rounded-md text-center'>
                              У пользователя нет ключей
                           </div>
                     }
                  </div>
               }
            </>
            :
            <h1 className='text-4xl text-center'>Пользователь не существует</h1>

   );
};

export default Server;