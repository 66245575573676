import {Link, useParams} from "react-router-dom";
import {useQuery} from "@tanstack/react-query";
import {getUserLogs} from "../api/userAPI"; 
import IsLoading from '../components/IsLoading';

const UserLogs = () => {
   const {id: chatId} = useParams()
   const {data: logs, isLoading} = useQuery({queryKey: [`user ${chatId}`, 'logs'], queryFn: () => getUserLogs(chatId)})
   return (
      <div>
         {isLoading?
            <h1 className='text-4xl text-center'>Подгрузка пользователя..</h1>
            :
            <h1 className='text-4xl text-center'><Link to={`/user/${chatId}`}>@{logs?.username}</Link> логи</h1>
         }

         <IsLoading isLoading={isLoading}>
            <div className="grid items-center gap-y-4 w-full mt-8">
               {
                  logs?.data?.length ?
                  <div>Всего ловов: {logs.data.length}</div>
                  :
                  <div>Нет логов</div>
               }
               {
                  logs?.data?.map(log =>
                     <div key={log.id} className={`bg-gray-100 p-4 rounded-md flex justify-between ${log.status && 'bg-green-200'}`}>
                        <p>{log.message}</p>
                        <p>{log.name}</p>
                        <p>ID_{log.keyId}</p>
                        <p>{log.createdAt.slice(0, 11).replace('T', ' ')}
                           <span className='text-xs text-green-700'>{log.createdAt.slice(11, 16).replace('T', ' ')}</span>
                        </p>
                     </div>
                  )
               }
            </div>
         </IsLoading>
      </div>
   );
};

export default UserLogs;