export function daysWithHoursToDate(timestampInSeconds) {
   // Преобразуем секунды в миллисекунды
   const dateInMillis = timestampInSeconds * 1000;
   
   // Вычисляем разницу между переданной датой и текущей
   const daysRow = (dateInMillis - new Date().getTime()) / (1000 * 3600 * 24);
   
   const days = Math.floor(daysRow);
   const hours = Math.floor(((daysRow * 100 % 100) * 24 / 100));
   
   if (days > 0) {
      return `${days}д`;
   } else {
      return `${hours}ч`;
   }
}